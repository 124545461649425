import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["rulesetOptions", "categoryOptions", "helpItemsOptions"];

  connect() {
    this.filterHelpItems();
  }

  filterHelpItems() {
    const selectedRuleset = this.rulesetOptionsTarget.value;
    const selectedCategory = this.categoryOptionsTarget.value;
    let firstVisibleOption = null;

    // Get all help item options
    const helpItemOptions = this.helpItemsOptionsTarget.querySelectorAll("option.help-item-option");
    helpItemOptions.forEach(option => {
      const isMatching = option.classList.contains(`ruleset-${selectedRuleset}-category-${selectedCategory}-help-item-option`);

      if (isMatching) {
        option.style.display = ""; // Show matching options
        if (!firstVisibleOption) {
          firstVisibleOption = option; // Capture the first visible option
          option.selected = true
        }
      } else {
        option.style.display = "none"; // Hide non-matching options
        option.selected = false
      }
    });

    if (!firstVisibleOption) {
      const blankOption = this.helpItemsOptionsTarget.querySelector('option[value=""]'); // Assuming your blank option has value=""
      if (blankOption) {
        blankOption.selected = true; // Select the blank option explicitly
      }
    }
  }
}
