
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "content", "breathingExercise", "counter", "message"];

  connect() {
    this.breathingCount = 10;
    this.breathingInterval = setInterval(() => this.updateBreathing(), 1500);
  }

  updateBreathing() {
    const displayCount = (this.breathingCount % 3) + 1;
    this.counterTarget.innerText = displayCount;

    if (this.breathingCount === 8) {
      this.messageTarget.innerText = "Hold";
    } else if (this.breathingCount === 5) {
      this.messageTarget.innerText = "And breathe out through your mouth";
    } else if (this.breathingCount === 2) {
      clearInterval(this.breathingInterval);

      this.containerTarget.remove();
      this.contentTarget.classList.remove("hidden");
    }
 
    this.breathingCount--;
  }
}
