import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  connect() {
    this.element.addEventListener("click", (e) => {
      let url = this.element.href;
      e.preventDefault();
      document.dispatchEvent(new Event("turbo:before-fetch-request"));
      get(url, { responseKind: "turbo-stream" });
    });
  }
}
