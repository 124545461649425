import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tabContent", "nudgesContent"];

  rulesetSelected(evt) {
    const ruleset = evt.target.value;
    const category = this.getCategoryId();
    this.updatePage(ruleset, category);
  }

  categorySelected(evt) {
    const category = evt.target.value;
    const ruleset = this.getRulesetId();
    this.updatePage(ruleset, category);
  }

  updatePage(ruleset, category) {
    // Build the URL with the selected parameters
    const url = `/help_items?ruleset_id=${ruleset}&category_id=${category}`;

    // Redirect the browser to the new URL (full page reload)
    window.location.href = url;
  }

  getRulesetId() {
    return document.getElementById('select-ruleset-id').value;
  }

  getCategoryId() {
    return document.getElementById('select-category-id').value;
  }
}
