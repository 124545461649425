import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  close() {
    document.getElementById("rippleModal").style.display = "none";
  }
}

window.onload = function(){
  const classes = document.getElementsByClassName('pre-defined-video');
  for(var i = 0; i < classes.length; i++) {
    classes[i].addEventListener('click', function (e) {
      e.preventDefault();
      const modal = document.getElementById(this.id+'_modal'); // modal
      modal.style.display = "flex"; // Show modal
    })
  }

  const modalClose = document.getElementsByClassName('modal-close');
  for (var i = 0; i < modalClose.length; i++) {
    modalClose[i].addEventListener('click', function (e) {
      e.preventDefault();
      const modal = document.getElementById(this.getAttribute('data-preview')+'_modal'); // modal
      const video = document.getElementById(this.getAttribute('data-preview')+'_video_player'); // video
      video.pause();
      modal.style.display = "none"; // Hide modal
    })
  }
}
