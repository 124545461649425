import { Controller } from "@hotwired/stimulus";

const active = ["text-purple-500", "border-purple-500"];
const inactive = "text-gray-500";

export default class extends Controller {
  static targets = [
    "galleryTabHead",
    "uploadTabHead",
    "galleryTab",
    "uploadTab",
    "gallery",
    'chooseFromMediaLibrary',
    'mediaLibraryModal',
    'imagePreview',
    'mediaIdInput',
    'fileInputField',
  ];

  connect() {
    this.showGallery();
  }

  close() {
    this.chooseFromMediaLibraryTarget.classList.remove("hidden");
    this.mediaLibraryModalTarget.classList.add('hidden');
    this.showGallery();
  }

  open() {
    this.chooseFromMediaLibraryTarget.classList.add("hidden");
    this.mediaLibraryModalTarget.classList.remove('hidden');
  }

  showGallery() {
    this.galleryTabHeadTarget.classList.add(...active);
    this.uploadTabHeadTarget.classList.remove(...active);
    this.galleryTabTarget.classList.remove("hidden");
    this.uploadTabTarget.classList.add("hidden");
  }

  showUpload() {
    this.uploadTabHeadTarget.classList.add(...active);
    this.galleryTabHeadTarget.classList.remove(...active);
    this.galleryTabTarget.classList.add("hidden");
    this.uploadTabTarget.classList.remove("hidden");
  }

  selectImage(event) {
    const mediaId = event.currentTarget.dataset.mediaLibraryId;
    const mediaUrl = event.currentTarget.dataset.mediaLibraryUrl;

    this.imagePreviewTarget.innerHTML = `<img src="${mediaUrl}" class="w-[50px] h-[50px] object-cover" alt="Selected Image">`;
    this.mediaIdInputTarget.value = mediaId;
    this.fileInputFieldTarget.value = null;

    this.close();
  }

  handleImageUpload(event) {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreviewTarget.innerHTML = `<img src="${e.target.result}" class="w-[50px] h-[50px] object-cover" alt="Uploaded Image">`;
        this.mediaIdInputTarget.value = null;

        this.close();
      };
      reader.readAsDataURL(file);
    }
  }
}
