import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sidebar", "rules", "rule", "ruleForm"];

  newRule() {
    this._openSidebar();
  }

  removeRule(evt) {
    evt.preventDefault();

    const button = evt.target;
    const row = button.closest("tr.rule");
    const destroyInput = row.querySelector("input[name*='_destroy']");
    const message = "Are you sure you want to remove this rule?";
    if (confirm(message)) {
      if (destroyInput) {
        row.style.display = "none";
        destroyInput.value = "1";
      } else {
        // Not an old record so just delete it
        row.remove();
      }
    }

    return false;
  }

  ruleSaved(evt) {
    const rule = evt.detail;
    this._insertNewRule(rule);
  }

  _createRuleInput(name, value) {
    const input = document.createElement("input");
    input.name = name;
    input.type = "hidden";
    input.value = value;

    return input;
  }

  _insertNewRule(rule) {
    const list = this.rulesTarget;
    const ruleElem = this.ruleTarget.content.cloneNode(true);
    const descriptionElem = ruleElem.querySelectorAll(".rule-name")[0];
    const inputsElem = ruleElem.querySelectorAll(".inputs")[0];

    descriptionElem.textContent = rule.summary;

    Object.keys(rule.values).forEach((inputName) => {
      const value = rule.values[inputName];
      inputsElem.appendChild(this._createRuleInput(inputName, value));
    });

    list.appendChild(ruleElem);
    this.ruleFormTarget.remove();
  }

  _openSidebar() {
    const sidebar = this.sidebarTarget.content.cloneNode(true);
    this.element.appendChild(sidebar);
  }
}
