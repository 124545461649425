import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["previewModal", "previewModalForm", "categorySelect", "severitySelect"];

  open(event) {
    event.preventDefault();

    // Get the ruleset_id from the data attribute on the preview link
    const rulesetId = event.currentTarget.dataset.rulesetId;

    this.previewModalFormTarget.action = `preview/${rulesetId}`

    // Show the modal
    this.previewModalTarget.classList.remove("hidden");
  }

  close() {
    this.previewModalTarget.classList.add("hidden");
  }

  submitPreview(event) {
    event.preventDefault();

    // Get the selected category and severity values
    const categoryId = this.categorySelectTarget.value;
    const severity = this.severitySelectTarget.value;

    // Construct the final URL with query parameters
    const formAction = this.previewModalFormTarget.action;
    const url = `${formAction}?category_id=${categoryId}&severity=${severity}`;

    // Open the URL in a new tab
    window.open(url, "_blank");
  }
}
