import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

const ruleTypes = {
  IP_RANGE: "ipRangeFields",
  IP_ADDRESS: "ipAddressFields",
  UNIQUE_CODE: "uniqueCodeFields",
  LOCATION: "locationFields",
};

const inputTypes = {
  IP_RANGE: "ipRangeInput",
  IP_ADDRESS: "ipAddressInput",
  UNIQUE_CODE: "uniqueCodeInput",
  LOCATION: "locationInput",
};

export default class extends Controller {
  static targets = [
    "validationErrors",
    "fields",

    "ipRangeFields",
    "ipAddressFields",
    "uniqueCodeFields",
    "locationFields",

    "ruleTypeInput",
    "ipRangeInput",
    "ipFrom",
    "ipTo",
    "ipAddressInput",
    "ipAddress",
    "uniqueCodeInput",
    "uniqueCode",
    "locationInput",
    "location",

    "locationCountryInput",
    "locationSubregionInput",
    "locationPathInput",
  ];

  static values = {
    ruleType: String,

    ipFrom: String,
    ipTo: String,
    ipAddress: String,
    uniqueCode: String,
    locationPath: String,
  };

  connect() {
    this.activeRuleType = "IP_RANGE";
    this.ruleID = new Date().getTime();
    this.locationPath = "";
    this.locationPathLabels = "";
  }

  ruleTypeSelected(evt) {
    const target = evt.target;
    const ruleType = target.value;

    this._toggleInputFields(ruleType);
  }

  countryPicked(evt) {
    const trigger = evt.target;
    this._refreshLocationPath(trigger);
    this._loadSubRegions();
  }

  saveRule(evt) {
    evt.preventDefault();

    if (this._validate()) {
      const ruleTypeInput = this.ruleTypeInputTarget;

      let payload = {
        ruleID: this.ruleID,
        summary: this._generateRuleSummary(),
        newRule: true,
        values: {},
      };

      payload.values[this._cleanName(ruleTypeInput.name)] = ruleTypeInput.value;

      if (this.activeRuleType === "LOCATION") {
        // Location is a little different since we build a
        // location path, rather than haveing a single input
        const input = this.locationPathInputTarget;
        payload.values[this._cleanName(input.name)] = this.locationPath;
      } else {
        const inputTarget = inputTypes[this.activeRuleType];
        const inputs = this[`${inputTarget}Targets`];
        inputs.forEach((inp) => {
          payload.values[this._cleanName(inp.name)] = inp.value;
        });
      }

      this.dispatch("ruleSaved", {
        prefix: "",
        detail: {
          ...payload,
        },
      });
    }

    return false;
  }

  subregionPicked(evt) {
    const trigger = evt.target;
    this._refreshLocationPath(trigger);
    this._loadSubRegions();
  }

  _cleanName(name) {
    return name.replace("NEW_RECORD", this.ruleID);
  }

  _generateRuleSummary() {
    switch (this.activeRuleType) {
      case "IP_RANGE":
        return this._ipRangeSummary();

      case "IP_ADDRESS":
        return this._ipAddressSummary();

      case "UNIQUE_CODE":
        return this._uniqueCodeSummary();

      case "LOCATION":
        return this._locationSummary();
    }
  }

  _hideFields(ruleType) {
    const target = `${ruleTypes[ruleType]}Target`;
    const fields = this[target];
    const classList = fields.classList;

    classList.remove("active");
    classList.add("hidden");
  }

  _ipAddressSummary() {
    const ipAddress = this.ipAddressTarget.value;

    return `Requesting IP address is equal to ${ipAddress}`;
  }

  _ipRangeSummary() {
    const ipFrom = this.ipFromTarget.value;
    const ipTo = this.ipToTarget.value;

    return `Requesting IP address is in the range of ${ipFrom} and ${ipTo}`;
  }

  _loadSubRegions() {
    get(`/rulesets/subregions?path=${this.locationPath}`, {
      responseKind: "turbo-stream",
    });
  }

  _locationSummary() {
    return `Request originated from ${this.locationPathLabels}`;
  }

  _refreshLocationPath(target) {
    const country = this.locationCountryInputTarget.value;
    let path = country;
    let labels =
      this.locationCountryInputTarget.selectedOptions[0]?.textContent;

    // Only process the subregion inputs if it was
    // a subregion that changed. If not, then we'll
    // be replacing those anyway
    if (target !== this.locationCountryInputTarget) {
      // A subregion input. Make sure we only build the path
      // up to the selected one, and no inputs after it
      const idxKey = "data-location-part-index";
      const triggerIdx = target.getAttribute("data-location-part-index");

      this.locationSubregionInputTargets
        .sort((a, b) => {
          const aIdx = a.getAttribute(idxKey);
          const bIdx = b.getAttribute(idxKey);

          return parseInt(aIdx) - parseInt(bIdx);
        })
        .forEach((input) => {
          const idx = parseInt(input.getAttribute(idxKey));

          if (input.value !== "" && idx <= triggerIdx) {
            path += `>${input.value}`;
            labels += ` > ${input.selectedOptions[0]?.textContent}`;
          }
        });
    }

    this.locationPath = path;
    this.locationPathLabels = labels;
  }

  _showFields(ruleType) {
    const target = `${ruleTypes[ruleType]}Target`;
    const fields = this[target];
    const classList = fields.classList;

    classList.remove("hidden");
    classList.add("active");
  }

  _toggleInputFields(ruleType) {
    this._hideFields(this.activeRuleType);
    this._showFields(ruleType);

    this.activeRuleType = ruleType;
  }

  _uniqueCodeSummary() {
    const uniqueCode = this.uniqueCodeTarget.value;

    return `Request has the unique code "${uniqueCode}"`;
  }

  _validate() {
    let valid = false;
    let message;

    if (this.activeRuleType === "IP_RANGE") {
      valid =
        this._validateIpAddress(this.ipFromTarget.value) &&
        this._validateIpAddress(this.ipToTarget.value);

      if (!valid) {
        message = "One or more IP address is not valid";
      }
    } else if (this.activeRuleType === "IP_ADDRESS") {
      valid = this._validateIpAddress(this.ipAddressTarget.value);
      if (!valid) {
        message = "IP address is not valid";
      }
    } else if (this.activeRuleType === "UNIQUE_CODE") {
      valid = this.uniqueCodeTarget.value.trim() !== "";

      if (!valid) {
        message = "You must enter a unique code";
      }
    } else {
      valid = this.locationCountryInputTarget.value !== "";
      if (!valid) {
        message = "You must choose at least a country";
      }
    }

    if (!valid) {
      this.validationErrorsTarget.textContent = message;
      this.validationErrorsTarget.classList.remove("hidden");
    }

    return valid;
  }

  _validateIpAddress(address) {
    const regex =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    return address.match(regex);
  }
}
