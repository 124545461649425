import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "content"]

  connect() {
    this.showTab(0)
  }

  showTab(index) {
    this.tabTargets.forEach((tab, i) => {
      if (i === index) {
        tab.classList.remove("bg-white", "text-gray-500")
        tab.classList.add("bg-purple-600", "text-white", "active")
        this.contentTargets[i].classList.remove("hidden")
      } else {
        tab.classList.remove("bg-purple-600", "text-white", "active")
        tab.classList.add("bg-white", "text-gray-500")
        this.contentTargets[i].classList.add("hidden")
      }
    })
  }

  switchTab(event) {
    event.preventDefault()
    const index = this.tabTargets.indexOf(event.currentTarget)
    this.showTab(index)
  }
}
