import NestedForm from "stimulus-rails-nested-form";

export default class extends NestedForm {
  static targets = ["expansionInputs", "openingTimeInputs", "target", "urlInput", "urlTextTemplate", "helpItemScheduleTarget", "helpItemScheduleTemplate"]

  connect() {
    super.connect()

    this.urlMapping = {};
    this.urlInputTargets.forEach(input => {
      this.urlMapping[input.closest(this.wrapperSelectorValue).getAttribute("data-idx")] = input.value;
    });
  }

  toggleExpansionInputs(evt) {
    this.expansionInputsTargets.forEach(target => {
      this._toggleInputs(
              evt.target,
              target);
    })
    
  }

  add(e) {
    // Not going to call super, as we want to build the new element manually,
    // purely so we can have access to the new content identifier
    e.preventDefault();

    const idx = new Date().getTime().toString();
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, idx);

    this.targetTarget.insertAdjacentHTML('beforebegin', content)
    this.urlMapping[idx] = "";

    // Now insert a url text input for each locale
    this.urlTextTemplateTargets.forEach(template => {
      const translationInput = template.innerHTML.replace(/NEW_RECORD/g, idx);
      template.insertAdjacentHTML("beforebegin", translationInput);
    });
  }

  addHelpItemSchedule(e) {
    // Not going to call super, as we want to build the new element manually,
    // purely so we can have access to the new content identifier
    e.preventDefault();

    const idx = new Date().getTime().toString();
    const content = this.helpItemScheduleTemplateTarget.innerHTML.replace(/NEW_RECORD/g, idx);

    this.helpItemScheduleTargetTarget.insertAdjacentHTML('beforebegin', content);
  }

  urlChanged(e) {
    const input = e.target;
    const urlValue = input.value;
    const idx = input.closest(this.wrapperSelectorValue).getAttribute("data-idx");

    this.urlMapping[idx] = urlValue;
    
    const labels = document.querySelectorAll(`label[data-idx="${idx}"] small`);
    labels.forEach(label => label.textContent = `URL: ${urlValue}`);
  }

  remove(e) {
    const wrapper = e.target.closest(this.wrapperSelectorValue)
    const idx = wrapper.getAttribute("data-idx")
    const labels = document.querySelectorAll(`label[data-idx="${idx}"`);
    labels.forEach(label => label.closest(this.wrapperSelectorValue).remove());

    super.remove(e);
  }

  removeHelpItemSchedule(e) {
    super.remove(e);
  }

  toggleOpeningTimeInputs(evt) {
    this.openingTimeInputsTargets.forEach(target => {
      this._toggleInputs(
        evt.target,
        target,
        true
      );
    })
  }

  _toggleInputs(checkbox, target, inverse = false) {
    const shouldShow = inverse ? !checkbox.checked : checkbox.checked;
    const classList = target.classList;

    if (shouldShow) {
      // Show the content
      classList.remove("hidden");
    } else {
      classList.add("hidden");
    }
  }
}
