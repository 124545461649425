import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["sidebar"];

  close() {
    const sidebar = this.sidebarTarget;
    const mode = sidebar.getAttribute("data-sidebar-close-method") || "remove";

    if (mode === "remove") {
      sidebar.remove();
    } else if (mode === "hide") {
      sidebar.classList.add("hidden");
    }
  }
}
