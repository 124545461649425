import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    const inputLength = this.inputTarget.value.length;
    this.inputTarget.setSelectionRange(inputLength, inputLength);
  }

  update(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const form = this.element;
      const searchQuery = this.inputTarget.value;
      const frameId = this.inputTarget.dataset.searchFrame;

      Turbo.visit(`${form.action}?search=${searchQuery}`, { action: 'replace', frame: frameId });
    }, 500);
  }
}
